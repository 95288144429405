<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="openAddPage"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">新增</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal">
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="入库编号:"
                    label-for="inbound_no"
                    label-size="sm"
                >
                  <b-form-input
                      id="inbound_no"
                      v-model="condition.inbound_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库名称"
                    label-for="warehouse_name"
                    class="mb-1 required"
                >
                  <v-select
                      id="warehouse_name"
                      :options="getCodeOptions('warehouse')"
                      v-model="condition.warehouse_id"
                      class="select-size-sm"
                      :reduce="option => parseInt(option.value)"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="货主名称:"
                    label-for="company_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="company_name"
                      size="sm"
                      v-model="companyName"
                      @click="showCompanyModal()"
                      readonly
                      placeholder="点击搜索货主"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="申请人:"
                    label-for="creator"
                    label-size="sm"
                >
                  <b-form-input
                      id="creator"
                      size="sm"
                      v-model="creatorName"
                      @click="showUserModal()"
                      readonly
                      placeholder="点击搜索申请人"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="审核状态:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('inbound_status')"
                      :clearable="true"
                      v-model="condition.status"
                      class="select-size-sm"
                      placeholder="选择审核状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="inbound_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template #cell(inbound_id)="data">
          {{ data.item.inbound_id }}
        </template>

        <template #cell(inbound_no)="data">
          {{ data.item.inbound_no }}
        </template>

        <template #cell(warehouse_name)="data">
          {{ data.item.warehouse_name }}
        </template>

        <template #cell(company_name)="data">
          {{ data.item.company_name }}
        </template>

        <template #cell(arrival_time)="data">
          {{ toDate(data.item.arrival_time) }}
        </template>

        <template #cell(memo_wh)="data">
          <div :id="`memo-${data.item.inbound_id}`">
            <span v-if="data.item.memo_wh">
              {{ data.item.memo_wh.substring(0, 6) }}
              <span v-if="data.item.memo_wh.length>6">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo-${data.item.inbound_id}`" placement="top">
            {{ data.item.memo_wh }}
          </b-tooltip>
        </template>

        <template #cell(attachments_wh)="data">
          <div v-show="data.item.attachments_wh !== undefined&&data.item.attachments_wh !== '' ">
            <feather-icon icon="EyeIcon" @click="showAttachments(data.item.attachments_wh)"/>
          </div>
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(status)="data">
          {{ getCodeLabel('inbound_status', data.item.status) }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="toDetail(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">详情</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
      <!--/ form -->
      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          ref="loadingModal"
      >
        <p class="my-4">正在处理，请勿关闭浏览器!</p>
      </b-modal>
      <b-modal
          id="modal-attachments"
          centered
          ok-only
          ok-title="确认"
          size="lg"
          title="查看附件"
          ref="attachModal"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments_ids"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload :theme="'files'"
                                 :attachment_id="'attachments_ch'"
                                 :id="attachmentsStr"
                                 :object_type="'inbound'"
                                 :object_id="0"
                                 :readonly="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
          id="modal-user"
          ok-only
          ok-title="确认"
          @ok="onSelectUser"
          cancel-title="取消"
          centered
          size="lg"
          :title="'请选择申请人'"
          ref="userModal"
      >

        <user-select ref="userSelect">
        </user-select>
      </b-modal>
      <b-modal
          id="modal-company"
          ok-only
          ok-title="确认"
          @ok="onSelectCompany"
          cancel-title="取消"
          centered
          size="lg"
          :title="'请选择货主'"
          ref="companyModal"
      >
        <company-select
            ref="companySelect" :companyType="'Group'">
        </company-select>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem,BFormRadio, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import XyTab from "@/views/components/xy/XyTab";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { toDate,avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,isEmpty,lodopWatermark } from '@core/utils/filter'
import inboundListStore from "@/views/apps/inbound/list/inboundListStore";
import inboundList from './inboundList.js'
import axios from "@/libs/axios";
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {second} from "@core/utils/dayjs/utils";
import {useToast} from "vue-toastification/composition";
import {getLodop} from "@/libs/LodopFuncs";
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import CompanySelect from '@/views/apps/company/CompanySelect'

export default {
  components: {
    BCard,
    BModal,
    XyTab,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadio,
    BPagination,
    vSelect,
    XyInputButton,
    AttachmentUpload,
    UserSelect,
    CompanySelect,
  },
  data() {
    return {
      advanced_search_modal: false,
      attachmentsStr: "",
      creatorName: "",
      companyName: "",
    }
  },
  methods: {
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.creatorName = ""
      this.companyName = ""
      this.state.condition = {}
      store.commit('inboundList/updateCondition', this.state.condition)
      this.refetchData()
    },
    //详情
    toDetail(item){
      this.$router.push({name: 'apps-inbound-edit', query: { inboundId: item.inbound_id }});
    },
    //新增
    openAddPage: function () {
      this.$router.push({name: 'apps-inbound-edit'});
    },
    showAttachments: function (item) {
      this.attachmentsStr = item
      this.$refs['attachModal'].show()
    },
    showUserModal() {
      this.$refs['userModal'].show()
    },
    onSelectUser() {
      let data = this.$refs.userSelect.getSelected()
      if(data.length > 0){
        this.creatorName = data[0].full_name;
        this.state.condition.creator = data[0].user_id;
      }
    },
    showCompanyModal() {
      this.$refs['companyModal'].show()
    },
    onSelectCompany() {
      let data = this.$refs.companySelect.getSelected()
      if(data.length > 0){
        this.companyName = data[0].company_name;
        this.state.condition.company_id = data[0].company_id;
      }
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const toast = useToast()

    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('inboundList')) store.registerModule('inboundList', inboundListStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inboundList')) store.unregisterModule('inboundList')
    })
    onMounted(() => {
      state.condition = store.getters['inboundList/getCondition']
    })

    const advanced_search = function () {
      this.advanced_search_modal = !this.advanced_search_modal;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }
    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,


    } = inboundList(
        {}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      fromChildren,
      advanced_search,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      toDate,
      getCodeColor,
      getCodeOptions,
      isEmpty,
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
